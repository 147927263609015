.job--header {
    display: flex;
    justify-content: space-between;
}

.job--title {
    margin-top: -0.5em;
}

.company--info {
 font-style: italic;   
 margin-top: -1.5em;
}

.job--location {
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
    margin-bottom: -0.5em;
}

.job--period {
    font-style: italic;
}

.job--infos {
    padding: 1em;
}

.job--description {
    margin: -1.5em;
    display: inline-flex;
    justify-content: space-between;
    
}

.job--bullet {
    font-size: 30px;
    margin-right: 0.5em;
}

.job--description-item {
    align-self: center;

}

/* Extra small devices (phones, 450px and down) */
@media only screen and (max-width: 450px) {
            
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
    .job--description {
        margin: -1em;
    }
    
}