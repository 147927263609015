.post-page {
  max-width: 800px;
  margin: 0 auto;
}

.post-container {
  margin-top: 20px;
}

.post-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.post-header {
  margin-bottom: 30px;
  text-align: center;
}

.post-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: white;
}

.post-description {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
  text-align: justify;
  font-weight: bold;
  padding: 20px;
}

.post-content {
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  margin-left: 5vw;
  margin-right: 5vw;
}

.post-bullets {
  font-size: 16px;
  line-height: 1.6;
  margin-left: 20px;
  margin-bottom: 20px;
}

.code-block {
  background-color: #f5f5f5 !important;
  border-radius: 4px;
  padding: 15px !important;
  margin: 20px 0 !important;
  overflow-x: auto;
}

.unity-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 2px dashed #ddd;
  border-radius: 8px;
  width: 90vw;
  height: 60vh; 
  max-height: 80vh; 
  overflow: hidden; 
}

.unity-iframe {
  width: 98%;
  height: 98%;
  border: none;
}

.unity-container h2 {
  font-size: 20px;
  color: #555;
  margin: 0;
}

.github-link {
  text-align: center;
  margin: 40px 0;
}

.github-link a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #24292e;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 16px;
  transition: background-color 0.2s;
}

.github-link a:hover {
  background-color: #2f363d;
}

.github-link i {
  font-size: 20px;
}

@media (min-width: 1000px) {
  .unity-container {
    width: 800px;
    height: 600px;
  }
}