.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
  gap: 20px;
  padding: 40px 20px;
  margin: 0 auto; 
  max-width: 1200px;
}

.blog-item {
  display: flex;
  flex-direction: column;
  width: 100%; 
  max-width: 300px; 
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}


.blog-item img {
  margin-bottom: 1em;
  height: 200px;
  object-fit: fill; 
}


.blog-item h2 {
  font-size: 20px; 
  margin: auto; 
  color: var(--dark-gray); 
  font-weight: bold; 
}


.blog-item p {
  flex-grow: 1; 
  font-size: 14px; 
  margin: 0 10px 10px; 
  color: var(--light-gray); 
  line-height: 1.5; 
}


.blog-read-more {
  display: block; 
  margin-top: auto;
  margin-bottom: 10px; 
  padding: 10px 20px; 
  font-size: 14px; 
  font-weight: bold; 
  color: white; 
  background-color: var(--dark-gray); 
  border-radius: 4px; 
  text-decoration: none; 
  transition: background-color 0.3s ease, color 0.3s ease; 
}

.blog-read-more:hover {
  background-color: var(--light-gray); 
  color: var(--dark-gray); 
}


@media (min-width: 1400px) {
  .blog-list {
    max-width: 1400px; 
    gap: 30px; 
  }
}


@media (max-width: 600px) {
  .blog-list {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    gap: 15px; 
  }
}
