.education--header {
    display: flex;
    justify-content: space-between;
}

.education--title {
    margin-top: -0.5em;
}

.college--info {
 font-style: italic;   
 margin-top: -1.5em;
}

.education--location {
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
    margin-bottom: -0.5em;
}

.education--period {
    font-style: italic;
}

.education--infos {
    padding: 1em;
}

.education--description {
    margin: -1.5em;
    display: inline-flex;
    justify-content: space-between;
    
}

.education--bullet {
    font-size: 30px;
    margin-right: 0.5em;
}

.education--description-item {
    align-self: center;

}