@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: 	#606060;
}

h1, h2, h3, h4, h5 {
  color: 	#282828;
  line-height: 1.2;
}

h1 {
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: 900;
  font-size: 45px;
}

h2 {
  font-weight: 700;
  font-size: 28px;
}

h3 {
  font-weight: 700;
  font-size: 22px;
}

p, a {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

span {
  font-weight: 500;
  font-size: 14px;
}

a:link {
  color: 	#606060;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: 	#606060;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: 	#404040;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: 	#909090;
  background-color: transparent;
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text cover */
.tooltip .tooltiptextcover {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0em 1em;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2em 1em;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptextcover, .tooltip:hover .tooltiptext {
  visibility: visible;
}