.languages--container {
    margin-top: -1.5em;
    display: inline-flex;
    flex-direction: column;
}

.languages {
    margin-bottom: -4em;
    display: inline-flex;
}

.language--bullet {
    font-size: 30px;
    margin-right: 0.5em;
}

.language--item {
    align-self: center;
}