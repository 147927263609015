.toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1em;
}  

.toggle .toggle-option {
    display: flex;
    width: 4%;
    max-width: 3em;
    min-width: 2em;
    justify-content: space-between;
}

.toggle img{
    cursor: pointer;
    width: 90%;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .toggle {
        margin-left: 10vw;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .toggle {
        margin-left: 15vw;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .toggle {
        margin-left: 20vw;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .toggle {
        margin-left: 25vw;
    }
}
