.website-links {
    display: flex;
    justify-content: center;
}

.website-links a {
    font-size: 24px;
    font-weight: 600;
    margin-left: 1.5em;
    margin-right: 1.5em;
    color: white;
}

.home-title {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-title .circular--portrait {
    width: 165px;
    height: 165px;
    overflow: hidden;
    border-radius: 50%;
    margin-top: 3em;
}

.home-title .circular--portrait img {
    width: 100%;
    height: auto;
}

.home-title .header {
    width: 50vw;
    text-align: center;
}

.home-title .header a {
    font-size: 32px;
    font-weight: bold;
    color: var(--dark-gray);
    overflow-wrap: break-word;
}

.home-title h4 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 2.5em;
    text-align: center;
    word-spacing: 2.5px;
    width: var(--full-width);
    color: var(--dark-gray);
}

.header-content-divisor {
    height: 2px;
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    width: calc(var(--full-width)/2);
    background-color: var(--light-gray);
}

.home-external-links {
    display: flex;
    margin-top: 15em;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.home-external-links .external-links-item a {
    display: flex;
    padding-left: 1em;
}

.external-links-item a img {
    width: 3em;
}

@media only screen and (min-width: 1200px) {
    .home-title .header {
        width: 30vw;
    }
    .home-title h4 {
        width: calc(var(--full-width)/2);
    }
}
