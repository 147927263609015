/* Container principal da página do blog */
.blog-main-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza horizontalmente */
  padding-top: 30px; /* Espaço abaixo do header */
  margin: 0 auto;
  width: 100%;
  max-width: 1400px; /* Limita a largura máxima */
}

/* Container específico para os posts */
.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
}

/* Estilo do título do blog */
.blog-title {
  font-size: 36px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px; /* Espaço entre o título e os posts */
  text-align: center;
} 

/* Responsividade para telas médias */
@media (max-width: 992px) {
  .blog-list {
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajusta os tamanhos dos cartões */
  }
}

/* Responsividade para telas pequenas */
@media (max-width: 600px) {
  .blog-list {
    grid-template-columns: 1fr; /* Uma coluna em telas pequenas */
    gap: 15px; /* Reduz o espaço entre os posts */
  }
}
