:root {
    --full-width: 60vw;
    --left-width: calc(var(--full-width)/3);
    --right-width: calc(var(--full-width)*(2/3));
    --curriculum-full-width: 60vw;
    --curriculum-left-width: calc(var(--curriculum-full-width)/3);
    --curriculum-right-width: calc(var(--curriculum-full-width)*(2/3));
    --heigth: 500px;
    --dark-gray: #404040;
    --light-gray: #A9A9A9;
    --right-content-margin: 2em;
    --left-content-margin: 3em;
}

.container {
    width: var(--full-width);
    margin-left: auto;
    margin-right: auto;
}

body {
    margin: 0;
    padding: 0;
  }
  
  .content-container {
    margin-top: 220px; /* Espaço equivalente à altura do header */
    padding: 20px;
  }