.curriculum-container {
    width: var(--curriculum-full-width);
    margin-left: auto;
    margin-right: auto;
}

.curriculum-title {
    display: flex;
    justify-content: space-between;
}
    .curriculum-title .portrait--area {
        width: var(--curriculum-left-width);
    }

    .curriculum-title .circular--portrait  {
        position: relative;
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 50%;
        margin-top: 2.5em;
        margin-left: 2em;
    }
    
    .curriculum-title .circular--portrait img {
        width: 100%;
        height: auto;
    }

    .curriculum-title .header {
        width: var(--curriculum-right-width);
        text-align: center;
    }

    .curriculum-title #subtitle {
        display: inline-flex;
    }
    
    .curriculum-title p, .title span, .curriculum-title a {
        font-weight: 400;
        font-size: 22px;
    }

    .curriculum-title span {
        align-self: center;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .curriculum-title a {
        align-self: center;
    }

.neck--line {
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: var(--dark-gray);
    height: 0.5em;
}

.body--divisor {
    width: 3px;
    height: auto;
    margin: 1em 2em 2em 1em;
    background-color: var(--light-gray);
}

.content--right-divisor {
    height: 2px;
    margin-top: 2em; 
    margin-bottom: 2em; 
    margin-left: auto;
    margin-right: auto;
    width: calc(var(--curriculum-right-width) - var(--right-content-margin));
    background-color: var(--light-gray);
}

.content--left-divisor {
    height: 2px;
    margin-top: 1.7em; 
    margin-bottom: 0.5em; 
    margin-left: auto;
    margin-right: auto;
    background-color: var(--light-gray);
    width: calc(var(--curriculum-left-width) - calc(var(--left-content-margin) * 2.2));
}

.curriculum-content--container {
    display: flex;
}

    #left-column {
        width: var(--curriculum-left-width);
        margin-left: 1.2em;
    }
    
    #right-column {
        width: var(--curriculum-right-width);
    }
    
    .curriculum-content--container .column {
        display: flex;
        flex-direction: column;
    }
        .curriculum-content--container .external-links {
            display: flex;
            flex-direction: column;
        }

        .curriculum-content--container .external-links-item {
            display: flex;
            margin-bottom: 0.5em;
        }

            .curriculum-content--container .external-links-item a {
                margin-left: 1em;
                align-self: center;
            }

            .curriculum-content--container .external-links-item img{
                width: 1.8em;
            }
            
        .content--right-div {
            margin-left: var(--right-content-margin);
        }
        .content--left-div {
            margin-right: var(--left-content-margin);
        }
            
    /* Extra small devices (phones, 450px and down) */
    @media only screen and (max-width: 450px) {
        :root{
            --curriculum-full-width: 250vw;
        }
        .body--divisor {
            margin: 0;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 450px) {
        :root{
            --curriculum-full-width: 200vw;
        }

        .body--divisor {
            margin: 0;
        }
        
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        :root{
            --curriculum-full-width: 150vw;
        }

        .body--divisor {
            margin: 0;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        :root{
            --curriculum-full-width: 120vw;
        }

        .body--divisor {
            margin: 0;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        :root{
            --curriculum-full-width: 60vw;
        }
    }
    
