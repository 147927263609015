.container--background {
  width: 100%;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: black;
}

/* Links do header */
.website-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.website-links a {
  font-size: 24px;
  font-weight: 600;
  margin: 0 15px;
  color: white;
  text-decoration: none;
}

.website-links a:hover {
  text-decoration: underline;
}

/* Título centralizado no header */
.header-title {
  font-size: 36px;
  font-weight: bold;
  color: white;
  margin-top: 15px;
  text-align: center;
}

/* Ajustes para telas pequenas (até 450px) */
@media (max-width: 450px) {
  .main-header {
    height: auto; /* Altura dinâmica para acomodar conteúdo */
    padding: 20px 10px; /* Espaçamento interno ajustado */
  }

  .website-links {
    flex-wrap: wrap; /* Permite que os links ocupem várias linhas, se necessário */
    margin-top: 5px;
  }

  .website-links a {
    font-size: 18px; /* Reduz o tamanho dos links */
    margin: 5px 10px; /* Ajusta o espaçamento entre os links */
  }

  .header-title {
    font-size: 24px; /* Reduz o tamanho do título */
    margin-top: 10px; /* Ajusta o espaço acima do título */
  }
}
